@import "variables";
//Shared

//Main Page file

.main-div {
  width: 720px;
  // border: 1px solid $primary;
  margin: 0 auto;
}

//Header file

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0;
  border: 0;
  height: 80px;
  margin-left: 10px;
  margin-right: 10px;
}

.nav-links-div {
  display: flex;
  justify-content: flex-start;
}

.nav-link {
  color: $secondary;
  font-weight: 400;
  padding: 5px;
  font-size: 0.875rem;
}

.logo-img {
  width: 150px;
  height: auto;
}

//Social media icons
.icons-button-div {
  display: flex;
  align-items: center;
  gap: 10px;
  img {
    width: 12px;
    height: auto;
  }
  .lk-icon {
    margin-right: 5px;
  }
}

.reservar-button {
  background-color: $primary;
  color: white;
  border-radius: 5px;

  &:hover {
    background-color: $primary-hover;
  }
}

//Information file
.main-title-div {
  font-size: 60px;
  text-align: center;
  font-weight: 500;
  color: $primary;
  padding-top: 40px;
  padding-bottom: 40px;
  margin-bottom: 60px;
  border-bottom: 1px solid $primary;

  span {
    position: relative;
    color: white;
    border-radius: 300px;
    background-color: $primary;
  }
}

.information {
  padding-right: 40px;
  padding-left: 40px;
  padding-bottom: 40px;
  text-align: justify;

  p {
    margin-bottom: 40px;
    span {
      color: $primary;
      font-weight: bold;
    }
    &:last-of-type {
      margin-bottom: 0px;
    }
  }
}
.disclaimer {
  font-weight: bold;
  border: 1.5px solid $primary;
  border-radius: 10px;
  padding: 15px;
}
.instrucciones {
  margin-top: 40px;
  span {
    color: $primary;
    font-weight: bold;
  }
  ul {
    list-style: disc;
    color: $primary;
    padding-left: 20px;
  }
  li {
    margin-bottom: 5px;
    text-align: start;
    color: $secondary;
    text-decoration: underline;
  }
}
.bullets-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    width: 250px;
  }
}

//Cuestionario file
.cuestionario-div {
  background-color: $tertiary;
  padding-bottom: 80px;

  span {
    font-weight: bold;
  }
  table {
    width: 638px;
    margin: 0 auto;
    //Styles for table border radius
    border-collapse: separate;
    border-spacing: 0;
  }
  .head-row {
    background-color: $primary;
  }

  th {
    color: white;
    padding: 10px;
  }

  td {
    color: $secondary;

    //Styles for table border radius
    border-style: none solid solid none;
    border-bottom: 1px solid rgba(184, 184, 184, 0.3);
    &:last-child {
      text-align: center;
    }
  }

  .checkboxes-td {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 86px;
  }

  .si-label,
  .no-label {
    margin-right: 5px;
    cursor: pointer;
    color: $secondary;
  }

  .no-label {
    margin-left: 15px;
  }

  //Start of styles for table borders and border radius
  tr:first-child th:first-child {
    border-top-left-radius: 10px;
    border-right: 1px solid rgba(184, 184, 184, 0.3);
  }
  tr:first-child th:last-child {
    border-top-right-radius: 10px;
    border-left: 1px solid rgba(184, 184, 184, 0.3);
  }
  td:first-child {
    border-left: 2px solid $primary;
    border-right: 1px solid rgba(184, 184, 184, 0.3);
    padding-left: 20px;
    padding-right: 10px;
  }
  td:nth-child(2) {
    font-size: 12px;
    padding: 15px;
    text-align: center;
  }
  td:last-child {
    border-right: 2px solid $primary;
    border-left: 1px solid rgba(184, 184, 184, 0.3);
    padding: 15px;
  }
  tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
  }
  tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
  }
  tr:first-child td {
    border-top-style: solid;
  }
  tr td:first-child {
    border-left-style: solid;
  }
  tr:last-child td {
    border-bottom: 2px solid $primary;
  }
  //End of styles for table border radius
}

.estimacion-div {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  margin-right: 45px;
  margin-left: 45px;
  margin-bottom: 12px;
  color: $secondary;
  font-weight: bold;
  span {
    color: $primary;
  }
}
.reiniciar-div {
  display: flex;
  justify-content: flex-end;
  p {
    font-weight: bold;
  }
  img {
    width: 25px;
    margin-left: 10px;
    cursor: pointer;
  }
}

//Resultados file
.resultados-div {
  margin: 0px 40px 0px 40px;
  span {
    color: $primary;
  }
}

.bar {
  position: relative;
  width: 100%;
  height: 40px;
  border: solid 2px $primary;
  border-radius: 10px;
  .inside-bar {
    position: relative;
    height: 100%;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    .inside-inside-bar {
      position: absolute;
      background-color: $primary;
      width: 6px;
      height: 100%;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }
  p {
    position: absolute;
    top: 5px;
    font-weight: bold;
  }
  .zero {
    left: 10px;
    z-index: 1;
  }
  .hundred {
    right: 10px;
    z-index: 1;
  }
  .alto-riesgo {
    top: 8px;
    position: absolute;
    display: flex;
    justify-content: center;
    p {
      // position: absolute;
      color: $primary;
      font-size: 22px;
      &:last-of-type {
        top: 28px;
      }
    }
  }
}

.information-result {
  margin-top: 100px;
  text-align: justify;
  border-width: 3px;
  border-style: solid;
  border-color: $primary;
  border-radius: 10px;
  padding: 20px;
}

.button-referir-div {
  display: flex;
  color: white;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  font-size: 15px;
  a {
    color: $primary;
    border-bottom: solid 1px #f78721;
  }
}

.flecha-percentage {
  position: absolute;
  display: flex;
  top: 50px;
  transition: all 1.5s;
  justify-content: center;

  img {
    width: 15px;
    margin-bottom: 20px;
    overflow: visible;
  }
}

.percentage {
  color: $primary;
  font-weight: bold;
  font-size: 44px;
}

.fuente {
  margin-top: 40px;
  text-align: justify;
  font-size: 11px;
  span {
    color: black;
    font-weight: 400;
  }
  a {
    color: $primary;
    border-bottom: solid 1px $primary;
  }
}

//Footer file
.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $secondary;
  padding-top: 40px;
  padding-bottom: 20px;
  div {
    margin-bottom: 20px;
  }
  p,
  button {
    color: white;
  }
  button {
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 5px;
    &:hover {
      background-color: #f89e4c;
    }
  }
}

.footer-icons-div {
  display: flex;
  align-items: center;
  gap: 10px;
  img {
    width: 12px;
    height: auto;
  }
}

.ai-button {
  background-color: $primary;
  color: white;
  border-radius: 20px;
  padding: 10px 40px;
  margin: 0 20px;
  &:hover {
    background-color: $primary-hover;
  }
}

.results-button {
  background-color: white;
  color: $primary;
  border-radius: 20px;
  padding: 10px 40px;
  border-style: solid;
  border-color: $primary;
  border-width: 1px;
  margin: 0 20px;
  &:hover {
    background-color: $primary;
    color: white;
  }
}

.confirm-button {
  background-color: $primary;
  color: white;
  &:hover {
    background-color: $primary-hover;
  }
}